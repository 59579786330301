import React, { useContext } from 'react';
import {
  contentHubContentCollectionSlug,
  contentHubContentCollectionName,
} from './ContentHubContentCollectionContext.config';

const ContentHubContentCollectionContext = React.createContext();
ContentHubContentCollectionContext.displayName = 'ContentHubContentCollectionContext';

const ContentHubContentCollectionProvider = ({
  contentCollection = 'magazine',
  contentCollectionSlug = 'magazine',
  children,
}) => {
  return (
    <ContentHubContentCollectionContext.Provider
      value={{ contentCollection, contentCollectionSlug }}
    >
      {children}
    </ContentHubContentCollectionContext.Provider>
  );
};

const useContentHubContentCollectionContext = () =>
  useContext(ContentHubContentCollectionContext) || {
    contentCollection: 'magazine',
    contentCollectionSlug: 'magazine',
  };

export {
  ContentHubContentCollectionProvider,
  ContentHubContentCollectionContext,
  useContentHubContentCollectionContext,
  contentHubContentCollectionSlug,
  contentHubContentCollectionName,
};
