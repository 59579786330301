import React from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { legacy__trackEvent } from 'src/services/analytics-service';
import { EVENTS } from 'src/services/analytics-constants';
import './index.scss';

const variantStyles = {
  default: 'px-lg py-sm',
  large: 'px-lg py-sm sm:px-xl sm:py-md',
};

/**
 * @deprecated - This is a legacy component
 */
export const PrimaryButton = ({
  isSubmitBtn = false,
  children,
  className = '',
  onClick,
  disabled,
  linkUrl,
  targetBlank = false,
  tag = 'button',
  variant = 'default',
  ctaBackgroundColor,
  trackingOptions = {},
  ...rest
}) => {
  const classString = `PrimaryButton PrimaryButton--refresh ${className} ${variantStyles[variant]}`;

  const style = {
    backgroundColor: ctaBackgroundColor,
  };

  const onCtaClick = ctaText => {
    const { event, ...customAttributes } = trackingOptions;

    legacy__trackEvent(event ?? EVENTS.CLICK_CTA, {
      type: 'Button',
      ctaText,
      ...customAttributes,
    });

    if (onClick) {
      onClick();
    }
  };

  if (tag === 'link') {
    return (
      <SmartLink
        href={linkUrl}
        className={classString}
        onClick={() => onCtaClick(children)}
        target={targetBlank ? '_blank' : ''}
        style={style}
        {...rest}
      >
        <span>{children}</span>
      </SmartLink>
    );
  }
  return (
    <button
      type={isSubmitBtn ? 'submit' : 'button'}
      disabled={disabled}
      className={classString}
      onClick={() => onCtaClick(children)}
      style={style}
      {...rest}
    >
      {children}
    </button>
  );
};
