import React from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { Image } from 'src/components/common/Image';
import { translations } from './MagFooterCTA.translations';
import generation from './media/generation-background.jpg';
import arrowRightSvg from './media/arrow-right.svg';
import './MagFooterCTA.scss';

export const MagFooterCTA = () => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const content = useTranslation('magazine::mag-footer-cta::defaults');

  return (
    <div className="MagFooterCTA__Container mx-auto">
      <div className="MagFooterCTA__Grid">
        <div className="MagFooterCTA__Image">
          <Image src={generation} alt="" />
        </div>
        <div className="MagFooterCTA__Copy flex flex-col items-center md:items-start md:max-w-xl z-10 text-center md:text-left">
          <p className="text-xxs uppercase font-medium tracking-doublewide">{content.topText}</p>
          <h2 className="mt-sm font-semibold text-greyscale-black text-2xl sm:text-3xl tracking-tight leading-none">
            {content.title}
          </h2>
          {content.description && (
            <p className="MagFooterCTA__Description mt-sm text-lg text-heading">
              {content.description}
            </p>
          )}
          <SmartLink
            className="MagFooterCTA__Link mt-md no-underline font-medium text-greyscale-black hover:text-greyscale-darker"
            href={content.linkUrl}
          >
            {content.linkText}
            <Image src={arrowRightSvg} alt="right arrow icon" />
          </SmartLink>
        </div>
      </div>
    </div>
  );
};
