import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { translations } from './TLDRSubsribeFormInsert.translations';
import { TLDRSubscribeForm } from '../TLDRSubscribeForm';
import { FluidText } from '../FluidText';
import {
  tldrInsertWrapper_,
  tldrInsertWrapperInline_,
  tldrInsertContainer_,
  tldrHeader_,
  tldrForm_,
  tldrLogo_,
  tldrLegalText_,
} from './TLDRSubsribeFormInsert.module.scss';
import tldr from './media/tldr.png';

const TLDRSubsribeFormInsert = ({ className, inline = false }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <div className={classNames(className, tldrInsertWrapper_, inline && tldrInsertWrapperInline_)}>
      <div className={tldrInsertContainer_}>
        {inline ? (
          <img className={tldrLogo_} src={tldr} alt="TLDR Newsletter Logo" />
        ) : (
          <>
            <FluidText type="p" min="ws-text-xl-medium" max="ws-display-xs-sans">
              {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
              {useTranslation('tldr_insert::title')}
            </FluidText>
            <FluidText
              className={tldrHeader_}
              type="h2"
              min="ws-display-md-sans"
              max="ws-display-lg-sans"
            >
              {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
              {useTranslation('tldr_insert::heading')}
            </FluidText>
          </>
        )}
        <FluidText type="p" min="ws-text-md" max="ws-text-xl">
          {useTranslation('tldr_insert::subtitle')}
        </FluidText>
        <TLDRSubscribeForm
          className={tldrForm_}
          buttonLabel={useTranslation('tldr_insert::button')}
          legalTextClassName={tldrLegalText_}
        />
      </div>
    </div>
  );
};

TLDRSubsribeFormInsert.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
};

export { TLDRSubsribeFormInsert };
