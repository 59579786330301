export const getThemeCtaContentFromCta = customCta => ({
  image: customCta?.image,
  imageUrl: customCta?.imageUrl,
  linkUrl: customCta?.linkUrl,
  linkText: customCta?.data?.linkText ?? customCta?.linkText,
  title: customCta?.head ?? customCta?.title,
  longTitle: customCta?.head ?? customCta?.longTitle,
  description: customCta?.subhead ?? customCta?.description,
  upperText: customCta?.data?.upperText ?? customCta?.upperText,
});
