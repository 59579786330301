import React, { useState, useLayoutEffect } from 'react';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { PrimaryButton } from 'src/components/legacy/primary-button';
import { classNames } from 'src/utils/css';
import { Image } from 'src/components/common/Image';
import { UnderlineButton } from '../UnderlineButton';
import { getThemeCtaContentFromCta } from './ThemeCta.helpers';
import generation from './media/generation-background.jpg';
import trade from './media/cta-trade.jpg';
import save from './media/cta-save.jpg';
import tax from './media/cta-tax.png';
import './ThemeCta.scss';
import { translations } from './ThemeCta.translations';

const LAYOUT_STICKY = 'sticky';
const LAYOUT_SEE_MORE = 'see-more';
const LAYOUT_FULL = 'full';
const LAYOUT_MOBILE = 'mobile';
const LAYOUT_INLINE = 'inline';

const THEMES = {
  generic: {
    imageUrl: generation,
  },
  cash: {
    imageUrl: generation,
  },
  trade: {
    imageUrl: trade,
  },
  save: {
    imageUrl: save,
  },
  invest: {
    imageUrl: trade,
  },
  tax: {
    imageUrl: tax,
  },
  crypto: {
    imageUrl: trade,
  },
  contentHub: {
    imageUrl: generation,
    ctaTheme: 'primary',
  },
};

const SUPPORTED_TAG_TYPES = ['li', 'div'];

export const ThemeCta = ({
  className = '',
  customContent,
  layoutType = LAYOUT_FULL,
  backgroundColor = '#fdf6f2',
  theme = 'generic',
  tag,
}) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const defaultContent = useTranslation('magazine::theme-cta::defaults');
  const content = getThemeCtaContentFromCta(customContent ?? defaultContent);
  const image =
    theme === 'custom'
      ? { src: content?.imageUrl }
      : { src: THEMES[theme].imageUrl };

  const [originLayoutType] = useState(layoutType);
  const [currentLayoutType, setCurrentLayoutType] = useState(layoutType);
  const [centerImage] = useState(image.src !== generation);
  const [layoutStates, setLayoutStates] = useState({
    originLayoutType: layoutType,
    blockName: `ThemeCta--${layoutType}`,
    currentLayoutType: layoutType,
    isCell: [LAYOUT_STICKY, LAYOUT_SEE_MORE].includes(layoutType),
    reverseRow: [LAYOUT_INLINE].includes(layoutType),
    reverseColumn: [LAYOUT_STICKY, LAYOUT_SEE_MORE, LAYOUT_MOBILE].includes(layoutType),
    isMobile: layoutType === LAYOUT_MOBILE,
  });

  useLayoutEffect(() => {
    setLayoutStates({
      blockName: `ThemeCta--${currentLayoutType}`,
      currentLayoutType,
      isCell: [LAYOUT_STICKY, LAYOUT_SEE_MORE].includes(currentLayoutType),
      reverseRow: [LAYOUT_INLINE].includes(currentLayoutType),
      reverseColumn: [LAYOUT_STICKY, LAYOUT_SEE_MORE, LAYOUT_MOBILE].includes(currentLayoutType),
      isMobile: currentLayoutType === LAYOUT_MOBILE,
    });
  }, [currentLayoutType, layoutType]);

  const onMobileLayout = () => {
    setCurrentLayoutType(LAYOUT_MOBILE);
  };

  const onDesktopLayout = () => {
    setCurrentLayoutType(originLayoutType);
  };

  useLayoutEffect(() => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      onMobileLayout();
    } else {
      onDesktopLayout();
    }
    const mobileMediaList = window.matchMedia('(max-width: 768px)');
    const desktopMediaList = window.matchMedia('(min-width: 769px)');
    mobileMediaList.addListener(onMobileLayout);
    desktopMediaList.addListener(onDesktopLayout);
    return () => {
      mobileMediaList.removeListener(onMobileLayout);
      desktopMediaList.removeListener(onDesktopLayout);
    };
  });

  let CardTag = 'div';
  if (SUPPORTED_TAG_TYPES.includes(tag)) {
    CardTag = tag;
  }

  return (
    <CardTag
      className={classNames(
        'ThemeCta',
        layoutStates.blockName,
        'w-full flex',
        layoutStates.isCell ? 'justify-end' : 'justify-center',
        layoutStates.reverseColumn && 'flex-col-reverse',
        layoutStates.reverseRow && 'flex-row-reverse',
        [LAYOUT_MOBILE, LAYOUT_INLINE].includes(originLayoutType) && 'mobile-border',
        className
      )}
    >
      <div
        className={classNames(
          `${layoutStates.blockName}__SegmentA`,
          layoutStates.isMobile ? 'text-center' : 'text-left',
          'text-greyscale-black'
        )}
        style={{ background: !layoutStates.isCell ? backgroundColor : 'transparent' }}
      >
        <div className={`${layoutStates.blockName}__CopyWrapper`}>
          {content.longTitle && (
            <h3
              className={classNames(
                'hidden',
                `${layoutStates.blockName}__LongTitle`,
                'w-1/2 font-semibold tracking-double-wide'
              )}
            >
              {content.longTitle}
            </h3>
          )}
          {content.upperText && (
            <p
              className={classNames(
                `${layoutStates.blockName}__UpperText`,
                'text-xxs uppercase tracking-doublewide font-medium'
              )}
            >
              {content.upperText}
            </p>
          )}
          <h3 className={`${layoutStates.blockName}__Title font-semibold mt-sm`}>
            {currentLayoutType !== LAYOUT_INLINE ? content.title : content.longTitle}
          </h3>
          <div className={layoutStates.isCell ? 'w-1/2' : ''}>
            <p
              className={`mt-md ${layoutStates.blockName}__Description ${
                layoutStates.isMobile ? 'mx-auto' : ''
              } text-body`}
            >
              {content.description}
            </p>
            {THEMES[theme]?.ctaTheme && THEMES[theme].ctaTheme === 'primary' ? (
              <PrimaryButton
                tag="link"
                linkUrl={content.linkUrl}
                className="mt-md md:mt-lg inline-block text-center"
              >
                {content.linkText}
              </PrimaryButton>
            ) : (
              <UnderlineButton
                className="text-greyscale-black text-xxs mt-md uppercase"
                linkText={content.linkText}
                linkUrl={content.linkUrl}
              />
            )}
          </div>
        </div>
      </div>
      <div className={`${layoutStates.blockName}__SegmentB relative w-full overflow-hidden`}>
        <div className={`${layoutStates.blockName}__Mask w-full`}>
          <Image
            className={classNames(
              `${layoutStates.blockName}__Image`,
              centerImage && 'center-image',
              'absolute min-w-auto h-full'
            )}
            alt=""
            {...image}
          />
        </div>
      </div>
    </CardTag>
  );
};
