import React from 'react';
import { classNames } from 'src/utils/css';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import './index.scss';

const SUPPORTED_TAG_TYPES = ['li', 'div', 'article', 'aside', 'nav', 'section', 'figure'];

/**
 * @deprecated - This is a legacy component
 */
export const Card = React.memo(function Card({
  children,
  className,
  tag,
  href = null,
  linkTitle = '',
  flat = false,
  stationary = false,
  carouselItem = false,
  ariaLabelledBy = null,
  ariaDescribedBy = [],
  ...rest
}) {
  const a11yProps = {};
  let CardTag = 'div';

  if (SUPPORTED_TAG_TYPES.includes(tag)) {
    CardTag = tag;
  }

  const classes = classNames(
    'Card',
    className,
    flat && 'Card--flat',
    stationary && 'Card--static',
    carouselItem && 'Card--carousel-item'
  );

  if (ariaLabelledBy) {
    a11yProps['aria-labelledby'] = ariaLabelledBy;
  }
  if (ariaDescribedBy.length) {
    a11yProps['aria-describedby'] = ariaDescribedBy;
  }

  return (
    <CardTag className={classes} data-link={href && true} {...rest}>
      {href && !stationary && (
        <SmartLink href={href} title={linkTitle} className="Card__link" {...a11yProps} />
      )}
      {children}
    </CardTag>
  );
});
