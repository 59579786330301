'use client';

import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { isWindowDefined } from 'src/utils/window';

export const useLatestArticlesLists = (latestArticles, mobileSizes, tabletSizes, desktopSizes) => {
  const numSizes = Math.max(mobileSizes.length, tabletSizes.length, desktopSizes.length);
  const [articleLists, setArticleLists] = useState(Array(numSizes).fill([]));

  const setLatestArticleBucketSizes = useCallback(
    (...sizes) => {
      let i = 0;
      setArticleLists(
        sizes.reduce((list, size) => {
          if (i + size < latestArticles.length - 1) {
            list.push(latestArticles?.slice(i, i + size) || []);
          } else {
            // empty array is flagging that there are not enough articles to fill section.
            list.push([]);
          }
          i += size;
          return list;
        }, [])
      );
    },
    [latestArticles?.slice]
  );

  useEffect(() => {
    // On server-side render, use desktop sizes
    if (!isWindowDefined()) {
      setLatestArticleBucketSizes(...desktopSizes);
    }
  }, []);

  useLayoutEffect(() => {
    const mobileMQL = window.matchMedia('(max-width: 479px)');
    const tabletMQL = window.matchMedia('(min-width: 480px) and (max-width: 959px)');
    const desktopMQL = window.matchMedia('(min-width: 960px)');
    const mediaListener = () => {
      if (mobileMQL.matches) {
        setLatestArticleBucketSizes(...mobileSizes);
      } else if (tabletMQL.matches) {
        setLatestArticleBucketSizes(...tabletSizes);
      } else if (desktopMQL.matches) {
        setLatestArticleBucketSizes(...desktopSizes);
      }
    };
    mobileMQL.addListener(mediaListener);
    tabletMQL.addListener(mediaListener);
    desktopMQL.addListener(mediaListener);
    mediaListener();
  }, [setLatestArticleBucketSizes]);

  return articleLists;
};
