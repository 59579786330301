export const translations = {
  'en-ca': {
    'magazine::theme-cta::defaults': {
      upperText: 'Wealthsimple',
      title: 'Grow your money',
      description:
        'Smart investing tools and personalized advice designed to build long term wealth.',
      linkText: 'Get started',
      linkUrl: '/',
    },
  },
  'fr-ca': {
    'magazine::theme-cta::defaults': {
      upperText: 'Wealthsimple',
      title: 'Faites fructifier votre argent',
      description:
        'Des outils d’investissement judicieux et des conseils personnalisés pour bâtir vos finances à long terme.',
      linkText: 'Inscription',
      linkUrl: '/',
    },
  },
};
