import React from 'react';
import './index.scss';

const AspectRatio = ({ children, ratio = '16/9', className = '' }) => {
  const style = {
    '--aspect-ratio': `calc(${ratio})`,
  };
  return (
    <div className={`AspectRatio relative overflow-hidden ${className}`} style={style}>
      <div className="AspectRatio__shell absolute w-full h-full">{children}</div>
    </div>
  );
};

export default AspectRatio;
