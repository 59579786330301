'use client';

import React, { useRef, useLayoutEffect, useState, useCallback } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { hexToRgb, getSafeRgbColor } from 'src/utils/colors/color-conversion';
import { translate } from 'src/utils/localization';
import { isMobileScreen } from 'src/utils/window';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useContentHubContentCollectionContext } from 'src/templates/content-hub/components/ContentHubContentCollectionContext';
import { UnderlineButton } from '../UnderlineButton';
import './ImageProgressor.scss';

gsap.registerPlugin(ScrollTrigger);

const HIDE_DURATION = 0.2;
const SHOW_DURATION = 0.2;
const SAFE_GRAYSCALE_INT = 180;

export const ImageProgressor = ({
  articles,
  textLayout = 'bottom',
  className = '',
  pageType,
  active,
}) => {
  const { locale } = useTranslationContext();
  const leftSelectRef = useRef();
  const rightSelectRef = useRef();
  const articleIndices = useRef({ active: 0, last: 0 });
  const overrideNavigationColors = useRef(false);
  const currentSlideOriginNavColor = useRef('#403e3d');
  const scrollTimeline = useRef();
  const scrollOutOfBounds = useRef(false);
  const isHiding = useRef(false);
  const timer = useRef();
  const articleRefs = [];
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  const { contentCollectionSlug } = useContentHubContentCollectionContext();
  const articleLink = article => `/${contentCollectionSlug}/${article.magazineArticlePageId}`;

  // default is automatically assigned as dominant color, but can be overridden from Contentful
  const getBackgroundColor = backgroundOverrideHexColor => {
    const color = getSafeRgbColor(hexToRgb(backgroundOverrideHexColor));
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  // Check if there is an override-color, else use dominant-color
  // and see if grayscale-average is smaller than limit.
  const isDarkMode = backgroundOverrideHexColor => {
    const color = getSafeRgbColor(hexToRgb(backgroundOverrideHexColor));
    return (color[0] + color[1] + color[2]) * 0.33 < SAFE_GRAYSCALE_INT;
  };

  const setNavColorVariable = darkMode => {
    // only index page ImageProgressor needs to update menu color in real-time
    if (pageType !== 'categories' && overrideNavigationColors.current) {
      // need to pass values over a global context to the navigation
      currentSlideOriginNavColor.current = darkMode ? '#fffbf8' : '#403e3d';
      if (scrollTimeline.current) {
        onScroll(scrollTimeline.current.scrollTrigger.scroll());
      }
    }
  };

  // navigation paddle interaction
  const blipNav = (left, right) => {
    const blippers = [];
    if (left) blippers.push(leftSelectRef.current);
    if (right) blippers.push(rightSelectRef.current);
    gsap.fromTo(blippers, { opacity: 0.01 }, { duration: 1, opacity: 0, ease: 'power2.inOut' });
  };

  const gotoArticle = (currentIndex, lastIndex, direction) => {
    if (timer.current) timer.current.kill();
    if (!active) return;
    articleHide(articleRefs[lastIndex], currentIndex, direction, () => {
      const currentArticle = articleRefs[currentIndex];
      articleShow(currentArticle);
      startTimer();
    });
  };

  const nextArticle = () => {
    articleIndices.current.last = articleIndices.current.active;
    articleIndices.current.active += 1;
    if (articleIndices.current.active > articleRefs.length - 1) articleIndices.current.active = 0;
    gotoArticle(articleIndices.current.active, articleIndices.current.last, 1);
  };

  const previousArticle = () => {
    articleIndices.current.last = articleIndices.current.active;
    articleIndices.current.active -= 1;
    if (articleIndices.current.active < 0) articleIndices.current.active = articleRefs.length - 1;
    gotoArticle(articleIndices.current.active, articleIndices.current.last, -1);
  };

  const startTimer = () => {
    const article = articleRefs[articleIndices.current.active];
    if (timer.current && timer.current.paused()) {
      timer.current.play();
    } else {
      if (timer.current) timer.current.kill();
      timer.current = gsap.fromTo(
        article.progress.current,
        { scaleX: 0, transformOrigin: 'top left' },
        { duration: 4, scaleX: 1, delay: 0, onComplete: () => nextArticle() }
      );
    }
  };

  const articleShow = (article, fadeInDuration = 0) => {
    setNavColorVariable(article.darkMode);
    // blink image and fade-in copy. first image load fades in
    gsap.to(article.ig.current, { duration: fadeInDuration, autoAlpha: 1 });
    gsap.fromTo(
      article.copy.current,
      { autoAlpha: 0, y: 5 },
      { delay: 0.4, duration: SHOW_DURATION, autoAlpha: 1, y: 0 }
    );
  };

  const articleHide = (article, currentIndex, direction, callback) => {
    // blocks interaction while last article hides and before current timer starts
    if (isHiding.current) return;
    isHiding.current = true;

    // hide copy
    gsap.fromTo(
      article.copy.current,
      { autoAlpha: 1 },
      {
        duration: HIDE_DURATION,
        autoAlpha: 0,
        onComplete: () => {
          gsap.set(article.ig.current, { autoAlpha: 0 });
          isHiding.current = false;
          if (callback) callback();
        },
      }
    );

    // reset progress bars
    articleRefs.forEach((ref, index) => {
      if (index < currentIndex) {
        gsap.to(ref.progress.current, { duration: HIDE_DURATION, scaleX: 1 });
      } else {
        gsap.to(ref.progress.current, {
          duration: HIDE_DURATION * 0.25,
          scaleX: 0,
        });
      }
    });
  };

  // assign darkmode to darker images
  /* eslint-disable react-hooks/rules-of-hooks */
  articles.forEach(article => {
    const darkMode = isDarkMode(article.articleImageBackgroundColorOverride);
    articleRefs.push({
      darkMode,
      ig: useRef(),
      copy: useRef(),
      progress: useRef(),
    });
  });
  /* eslint-enable react-hooks/rules-of-hooks */

  const onScroll = useCallback(scrollPercent => {
    // gets value between origin color and black.
    const navTextColor = gsap.utils.interpolate(
      currentSlideOriginNavColor.current,
      'rgba(64, 62, 61, 1)',
      scrollPercent
    );
    gsap.set('html', { '--nav-color': navTextColor });

    if (scrollOutOfBounds.current && scrollPercent < 1) {
      scrollOutOfBounds.current = false;
      if (timer.current) timer.current.play();
    } else if (!scrollOutOfBounds.current && scrollPercent >= 1) {
      scrollOutOfBounds.current = true;
      if (timer.current) timer.current.pause();
    }
  });

  const start = () => {
    articleShow(articleRefs[articleIndices.current.active], SHOW_DURATION);
    startTimer();
  };

  // everything that is dependent or points to a function using the window object
  useLayoutEffect(() => {
    let lastWidth = 0;

    const onResize = force => {
      // prevent resize on mobile chrome events
      const { innerWidth, innerHeight } = window;
      if (force !== true && window.screen.width === innerWidth && lastWidth === innerWidth) return;
      lastWidth = innerWidth;

      // set unique heights for tablet and phone
      gsap.set('.ImageProgressor', {
        height: isMobileScreen() ? innerHeight - 30 : '80vw',
      });

      // destroy gsap to prevent memory leaks
      if (scrollTimeline.current && scrollTimeline.current.scrollTrigger) {
        scrollTimeline.current.scrollTrigger.kill();
        scrollTimeline.current.kill();
      }

      // instances where we need to hi-jack the navigation icon and copy color
      overrideNavigationColors.current = pageType === 'index' && isMobileScreen();
      // watches for when scrolling goes out of bounds
      if (overrideNavigationColors.current) {
        scrollTimeline.current = gsap.timeline({
          duration: 1,
          scrollTrigger: {
            startTrigger: '.ImageProgressor',
            start: 'top+=500 top+=500',
            end: 'top+=500 top',
            scrub: true,
            onUpdate: e => {
              onScroll(e.progress);
            },
          },
        });
      } else {
        gsap.set('html', { '--nav-color': '#403e3d' });
      }
    };

    onResize(true);
    start();
    const delayedIntro = gsap.delayedCall(1.5, () => {
      blipNav(true, true);
    });

    window.addEventListener('resize', onResize);
    return () => {
      if (delayedIntro) delayedIntro.kill();
      if (scrollTimeline.current?.scrollTrigger) scrollTimeline.current.scrollTrigger.kill();
      if (scrollTimeline.current) scrollTimeline.current.kill();
      if (timer.current) timer.current.kill();
      window.removeEventListener('resize', onResize);
    };
  }, [firstImageLoaded]);

  useLayoutEffect(() => {
    if (!active && timer.current) {
      timer.current.pause();
    }
    if (active && timer.current) {
      // make sure IG is in view before starting timer
      timer.current.play();
      gsap.to('html', {
        duration: 0.45,
        '--nav-color': currentSlideOriginNavColor.current,
      });
    }
  }, [active]);

  useLayoutEffect(() => {
    // pre-intro values
    articleRefs.forEach(ref => {
      gsap.set([ref.ig.current, ref.copy.current], { autoAlpha: 0 });
      gsap.set(ref.progress.current, { scaleX: 0 });
    });
  }, []);

  return (
    <div
      className={`ImageProgressor ${className}`}
      style={{
        '--article-length': articles.length,
        backgroundColor: getBackgroundColor(articles[0].articleImageBackgroundColorOverride),
      }}
    >
      <div className="ImageProgressor__Frame">
        {articles.map((item, i) => {
          const {
            articleMetaImage,
            articleImageMobile,
            magazineArticlePageId,
            articleMetaTitle,
          } = item;
          // fallback for older magazine articles that don't have mobile images
          return (
            <div
              ref={articleRefs[i].ig}
              className="ImageProgressor__ImageWrap"
              key={magazineArticlePageId}
            >
              <picture>
                <source media="(max-width: 767px)" src={articleMetaImage?.url ?? articleMetaImage?.file?.url} />
                <img src={articleImageMobile?.url ?? articleImageMobile?.file?.url} alt={articleMetaImage.description ?? ''} onLoad={() => setFirstImageLoaded(true)} className="ImageProgressor__Image" />
              </picture>
              <div
                ref={articleRefs[i].copy}
                className="ImageProgressor__Copy text-center text-white"
                data-text-layout={textLayout}
                data-text-shadow="true"
              >
                <p className="ImageProgressor__Featured text-xxs uppercase font-medium tracking-doublewide DarkModeText">
                  {translate('featured', locale)}
                </p>
                <h2 className="ImageProgressor__Head text-xl font-semibold mt-sm mb-xs mx-auto leading-loose">
                  {articleMetaTitle}
                </h2>
                <UnderlineButton
                  className="ImageProgressor__Link text-white text-xxs uppercase mt-0"
                  linkText={translate('read-article', locale)}
                  linkUrl={articleLink(item)}
                />
              </div>
            </div>
          );
        })}
        <div className="ImageProgressor__Progress">
          {articles.map((item, i) => {
            const { magazineArticlePageId } = item;
            return (
              <div
                className="ImageProgressor__ProgressBar"
                key={`image-progress-bar-${magazineArticlePageId}`}
              >
                <span ref={articleRefs[i].progress} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="absolute flex justify-between h-full">
        <div
          ref={leftSelectRef}
          className="ImageProgressor__PaddleLeft absolute h-full bg-white opacity-0"
          onClick={previousArticle}
          onKeyPress={e => {
            if (e.keyCode === 37) previousArticle();
          }}
          role="button"
          tabIndex="0"
          label="previous article"
        />
        <div
          ref={rightSelectRef}
          className="ImageProgressor__PaddleRight absolute h-full bg-white opacity-0"
          onClick={nextArticle}
          onKeyPress={e => {
            if (e.keyCode === 39) nextArticle();
          }}
          role="button"
          tabIndex="0"
          label="next article"
        />
      </div>
    </div>
  );
};
