import React from 'react';
import { ArticleObject } from '../ArticleObject';
import './LatestArticlesSticky.scss';

export const LatestArticlesSticky = ({ articles = [], children, reverse = false }) => {
  return (
    <div
      className={`LatestArticlesSticky LatestArticlesSticky--${reverse ? 'reverse' : 'forward'}`}
    >
      {articles.map((article, index) => {
        return (
          <ArticleObject
            key={`latest-articles-sticky-${article.magazineArticlePageId}`}
            className={`cell-${index + 1}`}
            article={article}
            context="latest-articles"
          />
        );
      })}
      <div className="LatestArticlesSticky__StickyCell cell-cta">{children}</div>
    </div>
  );
};
