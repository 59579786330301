import { useRouter } from 'next/navigation';
import { navigate, prefetchPathname } from 'gatsby';
import { getFramework } from 'lib/get-framework';

export const useFrameworkRouter = () => {
  const { isGatsby } = getFramework();

  if (isGatsby) {
    return {
      push: navigate,
      prefetch: prefetchPathname,
    };
  }

  // No need to worry about rules-of-hooks here, since in the context of Gatsby
  // pages the hook will _never_ be called, and on Next pages the hook will
  // _always_ be called. Since pages from each framework don't share JS, this
  // means we'll never see the type of bug that rules-of-hooks aims to prevent.
  //
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return {
    // Since this is only currently used in Magazine, and we will soon migrate
    // that to use the Next.js router directly, for now we are replacing
    // client-side navigation with plain old redirects.
    push: path => { window.location.href = path; },
    prefetch: router.prefetch,
  };
};
