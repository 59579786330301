'use client';

import React, { useRef, useLayoutEffect, useState } from 'react';
import gsap from 'gsap';
import { getFramework } from 'lib/get-framework';
import { isDesktopScreen } from 'src/utils/window';
import { useFrameworkRouter } from 'src/hooks/useFrameworkRouter';

export const ImageExpand = ({ href, children, className = '' }) => {
  const { isGatsby } = getFramework();
  const router = useFrameworkRouter();
  const wrapperRef = useRef();
  const targetUrlRef = useRef();
  const [isMuted, setIsMuted] = useState(true);

  const transitionIn = (imgEl, originImgRect, originImgEl, originMaskEl) => {
    const background = document.createElement('div');
    const maskEl = document.createElement('div');
    // Transition ends after the article header image is loaded. Aritcle
    // page blindly looks for these classes and removes from body.
    background.className = 'ArticleOverlayBackground';
    maskEl.className = 'ArticleOverlayMask';
    maskEl.append(imgEl);
    document.body.append(background);
    document.body.append(maskEl);

    const originMaskRect = originMaskEl.getBoundingClientRect();

    gsap.fromTo(
      background,
      {
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 99,
        background: '#fffbf8',
        width: '100vw',
        height: '100vh',
        opacity: 0,
      },
      { duration: 0.5, opacity: 1, ease: 'power2.inOut' }
    );

    // lay mask over target image
    gsap.set(maskEl, {
      width: originMaskRect.width,
      height: originMaskRect.height,
      top: 0,
      left: 0,
      x: originMaskRect.x,
      y: originMaskRect.y,
      position: 'fixed',
      zIndex: 99,
      overflow: 'hidden',
    });

    let targetHeight;
    let aspectRatio;
    let targetWidth;

    targetWidth = window.innerWidth * 0.5;
    aspectRatio = imgEl.naturalHeight / imgEl.naturalWidth;
    targetHeight = Math.round(targetWidth * aspectRatio);

    // make sure hero image covers entire mask area
    if (targetHeight < window.innerHeight) {
      targetHeight = window.innerHeight;
      aspectRatio = imgEl.naturalWidth / imgEl.naturalHeight;
      targetWidth = Math.round(targetHeight * aspectRatio);
    }

    // Manual Crop: fake container for comapring ratios at a baseline/scale-1 height
    const scaleRatioWidth = (originImgRect.width * aspectRatio) / targetWidth;
    const scaleRatioHeight = originImgRect.height / targetHeight;

    // uses image in mask for cropping effect
    gsap.set(imgEl, {
      position: 'absolute',
      width: targetWidth,
      height: targetHeight,
      top: gsap.getProperty(originImgEl, 'top'),
      left: gsap.getProperty(originImgEl, 'left'),
      x: originImgRect.width * 0.5 - originImgRect.height * aspectRatio * 0.5,
      y: gsap.getProperty(originImgEl, 'y'),
      transformOrigin: '0% 0%',
      scaleX: scaleRatioWidth,
      scaleY: scaleRatioHeight,
      zIndex: 99,
      maxWidth: 'inherit',
    });

    // might be too long, but it also buys time for image to load
    const duration = 0.65;
    const delay = 1;
    gsap.to(maskEl, {
      duration,
      width: window.innerWidth * 0.5,
      height: window.innerHeight,
      y: 0,
      x: 0,
      delay,
      opacity: 1,
    });

    if (isGatsby) {
      gsap.to(imgEl, {
        duration,
        width: targetWidth,
        height: targetHeight,
        scale: 1,
        delay,
        top: 0,
        left: 0,
        x: (window.innerWidth * 0.5 - targetWidth) * 0.5,
        y: (targetHeight - window.innerHeight) * -0.5,
        onComplete: () => {
          // trigger gatsby router
          if (targetUrlRef.current) {
            router.push(targetUrlRef.current);
          }
        },
      });
    } else {
      router.push(targetUrlRef.current);
    }
  };

  const startEffect = e => {
    // default to natural behavior when muted
    if (isMuted || e.metaKey) return;

    // preload page
    if (targetUrlRef.current) {
      router.prefetch(targetUrlRef.current);
    }

    const imgsTags = wrapperRef.current.getElementsByTagName('img');
    const imgEl = imgsTags[imgsTags.length - 1];

    const imgUrl = imgEl.src;
    // duplicate target image
    const imgRect = imgEl.getBoundingClientRect();

    // There are two types of articles that have different
    // names for the masking element.
    let maskEl = imgEl.closest('.HeightParallax');
    if (!maskEl) maskEl = imgEl.closest('.AspectRatio');

    const targetEl = new Image();
    targetEl.onload = () => {
      transitionIn(targetEl, imgRect, imgEl, maskEl);
    };
    targetEl.src = imgUrl;

    e.preventDefault();
    e.stopPropagation();
  };

  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;

    const onResize = () => {
      setIsMuted(!isDesktopScreen());
    };
    onResize();

    // intercept child links
    const aTag = wrapper.getElementsByTagName('a')[0];
    if (aTag) {
      // store child link path, so it can be intercepted
      // and applied after click.
      targetUrlRef.current = new URL(aTag.href)?.pathname ?? href;
      aTag.addEventListener('click', startEffect);
    } else {
      // use attribute, in case there are no child links
      targetUrlRef.current = href;
    }

    window.addEventListener('resize', onResize);
    return () => {
      if (aTag) aTag.removeEventListener('click', startEffect);
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};
