import React from 'react';
import isEqual from 'react-fast-compare';
import { classNames } from 'src/utils/css';
import './page-section.scss';

/**
 * @deprecated Use Section instead
 */
export const PageSection = React.memo(function PageSection({
  id,
  children,
  className = '',
  collapse = false,
  ...rest
}) {
  const computedClassNames = classNames(
    'PageSection relative',
    collapse && 'PageSection--collapse',
    className
  );

  return (
    <section id={id} className={computedClassNames} {...rest}>
      {children}
    </section>
  );
},
isEqual);
