import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { useFrameworkRouter } from 'src/hooks/useFrameworkRouter';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import './UnderlineButton.scss';

export const UnderlineButton = ({
  linkText,
  linkUrl,
  className = '',
  ariaLabelledBy = null,
  manualState,
}) => {
  const wrapperRef = useRef();
  const ariaProps = {};
  const router = useFrameworkRouter();

  useLayoutEffect(() => {
    if (['active', 'inactive'].includes(manualState)) {
      wrapperRef.current.classList.remove('active');
      wrapperRef.current.classList.add(manualState);
    }
  }, [manualState]);

  const onMouseEnter = () => {
    wrapperRef.current.classList.add('active');
  };

  const onMouseLeave = () => {
    wrapperRef.current.classList.remove('active');
    wrapperRef.current.classList.add('inactive');
  };

  const onSelect = e => {
    e.preventDefault();
    e.stopPropagation();
    wrapperRef.current.classList.add('selected');
    gsap.delayedCall(0.15, () => {
      // check if relative link
      const link = wrapperRef.current.getElementsByClassName('UnderlineButton__Link')[0];
      if (link.href.indexOf(window.location.origin) > -1) {
        // trigger router
        const relativeUrl = link.href.replace(window.location.origin, '');
        router.push(relativeUrl);
      } else {
        // external link
        window.location.href = link.href;
      }
    });
  };

  useLayoutEffect(() => {
    const wrapperEl = wrapperRef.current;

    if (manualState === undefined) {
      wrapperEl.addEventListener('mouseenter', onMouseEnter);
      wrapperEl.addEventListener('mouseleave', onMouseLeave);
      wrapperEl.addEventListener('click', onSelect);
    }

    return () => {
      if (manualState === undefined) {
        wrapperEl.removeEventListener('mouseenter', onMouseEnter);
        wrapperEl.removeEventListener('mouseleave', onMouseLeave);
        wrapperEl.removeEventListener('click', onSelect);
      }
    };
  }, [manualState]);

  if (ariaLabelledBy) {
    ariaProps['aria-labelledby'] = ariaLabelledBy;
  }

  return (
    <div
      ref={wrapperRef}
      className={`UnderlineButton inline-block inactive text-greyscale-black font-medium tracking-doublewide ${className}`}
    >
      {linkUrl !== undefined ? (
        <SmartLink className="UnderlineButton__Link no-underline" href={linkUrl} {...ariaProps}>
          {linkText}
        </SmartLink>
      ) : (
        <>{linkText}</>
      )}
    </div>
  );
};
