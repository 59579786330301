'use client';

import React, { useId } from 'react';
import { classNames } from 'src/utils/css';
import { translate } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { legacyFontCondensed_ } from 'src/styles/legacy/futura-pt-condensed.module.scss';
import { UnderlineButton } from '../UnderlineButton';
import './ExcerptCta.scss';

export const ExcerptCta = ({ content = {} }) => {
  const { locale } = useTranslationContext();
  const excerptCtaId = `${useId()}-excerptCta`;

  return (
    <div className="ExcerptCta text-greyscale-black">
      <p className="mb-md text-xxs font-medium uppercase text-greyscale-black tracking-doublewide">
        {content.category}
      </p>
      <h2
        id={excerptCtaId}
        className={classNames(
          legacyFontCondensed_,
          'ExcerptCta__Excerpt uppercase tracking-tighter mb-md text-3xl md:text-4xl'
        )}
      >
        {content.excerpt}
      </h2>
      <p className="ExcerptCta__Description text-body mb-md">{content.description}</p>
      <UnderlineButton
        className="text-xxs uppercase tracking-doublewide font-medium"
        linkText={translate('read-article', locale)}
        linkUrl={`/magazine/${content.slug}`}
        ariaLabelledBy={excerptCtaId}
      />
    </div>
  );
};
