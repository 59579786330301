import React, { useRef, useLayoutEffect, useState } from 'react';
import gsap from 'gsap';
import { Image } from 'src/components/common/Image';

const TRAVEL_SCALAR = 0.3;

export const HeightParallax = ({
  backgroundColor = 'transparent',
  imageUrl,
  alt = '',
  originDimensions = { width: 0, height: 0 },
  className = '',
}) => {
  const maskRef = useRef();
  const imgRef = useRef();
  const maskVerticalCropAmount = useRef();
  const cachedDimensions = useRef();
  const elRect = useRef();
  const viewportHeight = useRef();
  const min = useRef();
  const max = useRef();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useLayoutEffect(() => {
    const setMask = (mask, img) => {
      // mask to fill screen height, minus some room for title and header
      const maskerHeight = cachedDimensions.current.winHeight - 335;
      gsap.set(mask, { height: `${maskerHeight}px` });

      // base dimensions from height, and center image
      let imgHeight = maskerHeight * (1 + TRAVEL_SCALAR);
      let imgWidth = Math.ceil(imgHeight * (originDimensions.width / originDimensions.height));

      // when image width doesn't fit mask make it width based
      if (imgWidth < cachedDimensions.current.maskRect.width) {
        imgHeight = Math.ceil(
          cachedDimensions.current.maskRect.width *
            (originDimensions.width / originDimensions.height) *
            (1 + TRAVEL_SCALAR)
        );
        imgWidth = Math.ceil(imgHeight * (originDimensions.width / originDimensions.height));
      }

      // center image
      const imgLeft = (cachedDimensions.current.maskRect.width - imgWidth) * 0.5;
      const imgTop = (maskerHeight - imgHeight) * 0.5;
      gsap.set(img, {
        maxWidth: 'inherit',
        width: imgWidth,
        height: imgHeight,
        left: imgLeft,
        top: imgTop,
      });
      maskVerticalCropAmount.current = TRAVEL_SCALAR * maskerHeight;
    };

    const onScroll = () => {
      elRect.current = maskRef.current.getBoundingClientRect();
      viewportHeight.current = cachedDimensions.current.winHeight;
      if (
        elRect.current.top < viewportHeight.current &&
        elRect.current.top > -elRect.current.height
      ) {
        max.current = viewportHeight.current + elRect.current.height;
        min.current = elRect.current.top + elRect.current.height;
        if (imgRef.current) {
          gsap.set(imgRef.current, {
            y:
              -(min.current / max.current) * (maskVerticalCropAmount.current * 0.5) +
              max.current * (TRAVEL_SCALAR / 10),
          });
        }
      }
    };

    const onResize = () => {
      cachedDimensions.current = {
        winHeight: window.innerHeight,
        maskRect: maskRef.current.getBoundingClientRect(),
        imgRect: imgRef.current.getBoundingClientRect(),
      };
      setMask(maskRef.current, imgRef.current);
      onScroll();
    };

    // need to manually assign here, since GatsbyImage no longer supports refs
    const imgEl = maskRef.current.getElementsByTagName('img')[0];
    imgRef.current = imgEl;

    if (isImageLoaded) {
      onResize();
      window.addEventListener('scroll', onScroll);
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, [isImageLoaded]);

  useLayoutEffect(() => {
    // sometimes images are cached and are loaded before javascript
    const imgEl = maskRef.current.getElementsByTagName('img')[0];
    if (imgEl.complete && imgEl.naturalHeight !== 0) {
      setIsImageLoaded(true);
    }
  }, []);

  return (
    <div ref={maskRef} className={`HeightParallax ${className} overflow-hidden w-full relative `}>
      <Image
        className="w-full h-full absolute block"
        onLoad={() => setIsImageLoaded(true)}
        src={`${imageUrl.split('?')[0]}?w=1000&h=1000&fm=jpg&f=center&fit=fill`}
        alt={alt}
        loading="eager"
        style={{ backgroundColor }}
      />
    </div>
  );
};
