export const translations = {
  'en-ca': {
    'tldr_insert::title': 'TLDR Newsletter',
    'tldr_insert::heading': 'Business news made simple',
    'tldr_insert::subtitle':
      'Sign up for our weekly non-boring newsletter about money, markets, and more. Sorry, TLDR is currently available in English only.',
    'tldr_insert::button': 'Subscribe',
  },
  'fr-ca': {
    'tldr_insert::title': 'Infolettre TLDR',
    'tldr_insert::heading': 'Les nouvelles économiques simplifiées',
    'tldr_insert::subtitle':
      'Abonnez-vous à notre amusante infolettre hebdomadaire sur l’argent, les marchés et plus encore. Nos excuses : TLDR n’est offerte qu’en anglais pour le moment.',
    'tldr_insert::button': 'Abonnez-vous',
  },
};
