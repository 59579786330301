'use client';

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import NextImage from 'next/image';
import { getFramework } from 'lib/get-framework';
import { classNames } from 'src/utils/css';
import { translate } from 'src/utils/localization';
import { hexToRgb, getSafeRgbColor } from 'src/utils/colors/color-conversion';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useContentHubContentCollectionContext } from 'src/templates/content-hub/components/ContentHubContentCollectionContext';
import { trackArticleClicked } from 'src/services/analytics-service';
import { Card } from 'src/components/legacy/card';
import AspectRatio from './aspect-ratio';
import { HeightParallax } from './height-parallax';
import './ArticleObject.scss';

export const ArticleObject = ({
  article = {},
  noCategory,
  noDesc,
  feature,
  ratio = '1/1',
  parallax = false,
  smallVariant = false,
  className = '',
  context = null,
  contextDetail = null,
  articleCategory = article?.magazineCategory,
  contentSlug = article?.magazineArticlePageId,
  suppressLink,
}) => {
  const { isGatsby } = getFramework();
  const { locale } = useTranslationContext();

  // default is automatically assigned as dominant color, but can be overridden from Contentful
  const getBackgroundColor = backgroundOverrideHexColor => {
    const color = getSafeRgbColor(hexToRgb(backgroundOverrideHexColor));
    return [color[0], color[1], color[2]];
  };

  // determine values from color information
  const rgb = getBackgroundColor(article.articleImageBackgroundColorOverride ?? '#000000');
  const backgroundColor = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  const textId = `${article.magazineArticlePageId}-text`;

  const { contentCollection, contentCollectionSlug } = useContentHubContentCollectionContext();
  const articleLink = !suppressLink && `/${contentCollectionSlug}/${contentSlug}`;

  const trackClicked = () => {
    trackArticleClicked({
      contentCollection,
      articleId: contentSlug,
      context,
      contextDetail,
    });
  };

  return smallVariant ? (
    <Card
      className={`ArticleObject ArticleObject--Small ${className}`}
      tag="article"
      flat
      href={articleLink}
      ariaLabelledBy={textId}
      onClick={() => trackClicked()}
    >
      <AspectRatio ratio={ratio}>
        {isGatsby ? (
          <GatsbyImage
            className="h-full"
            backgroundColor={backgroundColor}
            image={getImage(article.articleMetaImage)}
            alt={article.articleMetaImage?.description ?? ''}
          />
        ) : (
          <NextImage
            className="h-full"
            src={article.articleMetaImage.url}
            width={article.articleMetaImage.width}
            height={article.articleMetaImage.height}
            alt={article.articleMetaImage?.description ?? ''}
            style={{ backgroundColor, objectFit: 'cover' }}
          />
        )}
      </AspectRatio>
      <div className="ArticleObject__Copy">
        <p
          id={textId}
          className="ArticleObject__Title mt-0 text-greyscale-black font-sansbold font-bold leading-tight"
        >
          {article.articleMetaTitle}
        </p>
        {!noCategory && article.magazineCategory && (
          <p className="ArticleObject__Category text-greyscale-black font-medium tracking-doublewide uppercase">
            {translate(articleCategory, locale)}
          </p>
        )}
      </div>
    </Card>
  ) : (
    <Card
      className={`ArticleObject ArticleObject--Large ${className}`}
      tag="article"
      flat
      href={articleLink}
      ariaLabelledBy={textId}
      onClick={() => trackClicked()}
    >
      {parallax && article.articleMetaImage ? (
        <HeightParallax
          backgroundColor={backgroundColor}
          imageUrl={article.articleMetaImage?.url ?? article.articleMetaImage.file.url}
          originDimensions={{ width: 1000, height: 1000 }}
          alt={article.articleMetaImage?.description ?? ''}
        />
      ) : (
        <AspectRatio ratio={ratio}>
          {isGatsby ? (
            <GatsbyImage
              className="h-full ImageExpandMaskTarget"
              backgroundColor={backgroundColor}
              image={getImage(article.articleMetaImage)}
              alt={article.articleMetaImage?.description ?? ''}
            />
          ) : (
            <NextImage
              className="h-full ImageExpandMaskTarget"
              src={article.articleMetaImage.url}
              width={800}
              height={800}
              alt={article.articleMetaImage?.description ?? ''}
              style={{ backgroundColor, objectFit: 'cover' }}
            />
          )}
        </AspectRatio>
      )}
      <div className={feature ? 'mt-lg' : 'mt-md'}>
        {!noCategory && (
          <p className="ArticleObject__Category text-greyscale-black font-medium tracking-doublewide uppercase">
            {translate(
              articleCategory
                .toLowerCase()
                .split(' ')
                .join('-'),
              locale
            )}
          </p>
        )}
        <p
          id={textId}
          className={classNames(
            'ArticleObject__Title text-greyscale-black font-sansbold font-bold mt-xs',
            feature && 'px-lg'
          )}
        >
          {article.articleMetaTitle}
        </p>
        {!noDesc && (
          <p className="ArticleObject__Desc text-heading mt-md">
            {article.metaDescription?.metaDescription ?? article.metaDescription}
          </p>
        )}
      </div>
    </Card>
  );
};
