'use client';

import React, { useContext, useMemo, useState } from 'react';

const MagazineNavContext = React.createContext({});
MagazineNavContext.displayName = 'MagazineNavContext';

const useMagazineNavContext = () => useContext(MagazineNavContext);

const MagazineNavContextProvider = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [pageType, setPageType] = useState('article'); // index, about, article, category

  const state = useMemo(() => ({
    isNavOpen,
    setIsNavOpen,
    isDarkMode,
    setIsDarkMode,
    pageType,
    setPageType,
  }), [isNavOpen, setIsNavOpen, isDarkMode, setIsDarkMode, pageType, setPageType]);

  return (
    <MagazineNavContext.Provider value={state}>
      {children}
    </MagazineNavContext.Provider>
  );
};

export { MagazineNavContextProvider, MagazineNavContext, useMagazineNavContext };
